(function (module) {
    'use strict';

    var rfrSvc = function ($http, $q, $uibModal, $odata, odataSvc, rfrLogicSvc, amsConst, typeConstSvc, rfrProcessStatuses, helperSvc, currentUser, currentOrganization, membershipSvc, currentUserHelper,
        oauth, contactRoleTypes, messageSvc, messageTemplateTypes, commissionIndexes, organizationSvc, contactTypes, programReviewTypeIds, reviewTypeIds, messageTemplateParameters, alertSvc, programSvc, npcSvc, orgConstants, groupsAndRolesSvc, personSvc) {
        var factory = {};

        //http call paths
        var rfrHttpPath = amsConst.webApiUrl + '/odata/Rfr({0})?filter=rfrId ne 0';
        var valHttpPath = amsConst.webApiUrl + '/odata/ValidateRfr';
        var reviseRfrPath = amsConst.webApiUrl + '/odata/ReviseRfr';
        var acceptRfrPath = amsConst.webApiUrl + '/odata/AcceptRfr';
        var createNewRfrPath = amsConst.webApiUrl + '/odata/CreateNewRfr';

        //odataresource paths
        var api = '/Rfr';
        var searchApiRfr = '/GetRfrByOrganization';
        var searchApi = '/GetProgramArea';
        var programAreaSocietyApi = '/GetProgramAreaSociety';
        var validateApi = '/ValidateRfr';
        var key = 'rfrId';
        var organizationKey = 'organizationId';

        //commission errors used like { 0: [], 1: [], 2: [], 3: [] } for the different commission ids
        //program errors used like{ "Computer ScienceBS: "Error"} the program name and degreee is the attribute name
        factory.data = {
            rfr: null,
            cao: null,
            npcData: [],
            rfrErrors: [],
            commissionErrors: {},
            programErrors: {}
        };
                
        factory.slugs = {
            REVIEW: 'review',
            PROGRAMS: 'programs',
            CONTACTS: 'contacts',
            PREVIEW: 'preview',
            SIGN: 'sign',
            ACCEPT: 'accept',
            SUMMARY: 'summary'
        };

        factory.getRfrSubmissionAndCommissions = function (id) {
            var oSvc = odataSvc.get();

            oSvc.getSource(api, key).odata()
                .expand('rfrCommissionDtos')
                .get(id)
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            resolveRfrData(oSvc);

            const loadNpcData = npcSvc.getNpcProgramChangesForRfr(id).then(data => {
                factory.data.npcData = data.reduce((data, program) => {
                    const commissionId = program.commissionId;
                    data[commissionId] = data[commissionId] || [];
                    data[commissionId].push(program);
                    return data;
                }, []);
            });

            return $q.all([oSvc.getDeferred().promise, loadNpcData]).then(data => {
                const rfr = data[0];

                return membershipSvc.getAbetCAOs(rfr?.submittedTimestamp).then((data) => {
                    factory.data.cao = data?.length ? data[0] : null;
                    return rfr;
                }).catch(error => {
                    console.log(error);
                    factory.data.cao = null;
                    return rfr;
                });  
            });
        };

        factory.getRfrSubmissionsByOrganizationId = function (organizationId) {
            var oSvc = odataSvc.get();
            var searchApiRfrFilter = searchApiRfr + "(organizationId=" + organizationId + ")";

            oSvc.getSource(searchApiRfrFilter, key).odata()
                .expand('rfrCommissionDtos')
                .orderBy("reviewYear", "desc")
                .orderBy("revisedSeq", "desc")
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        }

        factory.create = function (rfr) {
            var oSvc = odataSvc.get();
            //get the # of pevs for all disciplines
            factory.calculateAllPevsForRfr(rfr);
            cleanValidationErrorArrays(rfr);
            setDefaultCriteriaCycleYear(rfr)
            var updatedRfr = serializeJsonContents(rfr);

            rfr.$save(null, oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        };

        factory.createRevisedRfr = function (rfr) {
            var oSvc = odataSvc.get();
            //get the # of pevs for all disciplines
            factory.calculateAllPevsForRfr(rfr);
            cleanValidationErrorArrays(rfr);
            setDefaultCriteriaCycleYear(rfr)
            rfr = serializeJsonContents(rfr);

            return $http.post(reviseRfrPath, rfr).then(function (response) {
                return response;
            });
        };

        factory.update = function (rfr) {
            var oSvc = odataSvc.get();
            //get the # of pevs for all disciplines
            factory.calculateAllPevsForRfr(rfr);
            cleanValidationErrorArrays(rfr);
            setDefaultCriteriaCycleYear(rfr)
            var updatedRfr = serializeJsonContents(rfr);

            var updatePromise = updatedRfr.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function (result) {
                    return factory.getRfrSubmissionAndCommissions(rfr.rfrId).then(function (data) {
                        //this is being called synchronously so that the validation invoke can be called with the updated data
                        if (Array.isArray(data))
                            factory.data.rfr = data[0];
                        else
                            factory.data.rfr = data;
                    });             
            });

            return updatePromise;
            
        };

        factory.delete = function (rfr) {
            var oSvc = odataSvc.get();

            if (confirm("Are you sure you want to delete this record?")) {
                var deletePath = amsConst.webApiUrl + '/odata/Rfr(' + rfr.rfrId + ')'
                return $http.delete(deletePath).then(function (response) {
                    return response;
                });
            }
            else {
                return $q.reject('user cancelled');
            }
        };

        factory.validate = function (rfr) {
            delete rfr['@odata.context'];
            var serRfr = angular.copy(rfr);
            cleanValidationErrorArrays(serRfr);
            var data = { "Value": serializeJsonContents(serRfr) };

            //Not working with this odata query - need to find a way            
            //data.$save(oSvc.onSuccess, oSvc.onFailure);
            //return oSvc.getDeferred().promise;

            return $http.post(valHttpPath, data).then(function (response) {
                return response;
            });
        };

        //Create new RFR when new institution setup made by ABET Staff and trigerred from On NGR and Commission widget of Admin Organization
        factory.createNewRfrByAdmin = function (organizationId,commissionId) {
            var data = {
                organizationId: organizationId,
                commissionId: commissionId
            };

            var oSvc = odataSvc.get();

            return $http.post(createNewRfrPath, data).then(function (response) {
                //send message to primary and secondary contact of this instiution and commission
                membershipSvc.getByOrganizationId(organizationId, true).then(function (data) {
                    var members = helperSvc.getResults(data);
                    var primaryAddresses = [];
                    var academicYear = helperSvc.getAcademicYear();
                    var messageParams = angular.copy(messageTemplateParameters.RFRCREATEDRFEFORNEWINSTITUTIONCOMMISSION);
                    messageParams[1].messageParameterValue = (academicYear).toString(); //Academic Year
                    messageParams[2].messageParameterValue = (academicYear + 1).toString(); //review cycle year
                    
                    for (var i = 0; i < members.length; i++) {

                        var member = members[i];
                        var role = member.roleId;

                        if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY || role === contactRoleTypes.DELEGATEFORPRIMARY) {

                            for (var j = 0; j < member.designationViewModels.length; j++) {
                                if (member.designationViewModels[j].designationId === commissionId && !helperSvc.arrContains(primaryAddresses, member.personId)) {
                                    primaryAddresses.push(member.personId);
                                    messageParams[0].messageParameterValue === '' ? messageParams[0].messageParameterValue = 'Dr. ' + member.lastName : messageParams[0].messageParameterValue += (' and ' + 'Dr. ' + member.lastName); //person last name
                                }
                            }
                        }
                    }
                    messageSvc.buildRecipientListAndSendMessages(primaryAddresses, [], messageTemplateTypes.CREATEDRFEFORNEWINSTITUTIONCOMMISSION, null, null, messageParams,contactTypes.INSTITUTIONCONTACTONLY);
                });
                return response;
            });
        };

        factory.accept = function (rfr) {
            delete rfr['@odata.context'];
            cleanValidationErrorArrays(rfr);
            setDefaultCriteriaCycleYear(rfr)
            rfr = serializeJsonContents(rfr);
            var data = { "Value": rfr };

            return $http.post(acceptRfrPath, data).then(function (response) {
                return factory.getRfrSubmissionAndCommissions(rfr.rfrId).then(function (data) {

                    membershipSvc.getByOrganizationId(factory.data.rfr.organizationId, true).then(function (data) {
                        var messageParams = angular.copy(messageTemplateParameters.RFRACCEPTED);
        
                        messageParams[1].messageParameterValue = (helperSvc.getAcademicYear()).toString() + '-' + rfr.reviewYear.toString().substr(2, 2); //review cycle year span
                        messageParams[3].messageParameterValue = helperSvc.getAcademicYear().toString(); //current cycle year
                        
                        angular.forEach(rfr.rfrCommissionDtos, function (commission) {
                            messageParams[2].messageParameterValue === '' ? messageParams[2].messageParameterValue = commission.commissionAbbreviatedName : messageParams[2].messageParameterValue += (', ' + commission.commissionAbbreviatedName); //commissions
                        });

                        var members = helperSvc.getResults(data);
                        var primaryAddresses = [];
                        var rfrCommissionIds = getCommissionIds(factory.data.rfr);


                        for (var i = 0; i < members.length; i++) {
                            var member = members[i];
                            var role = member.roleId;

                            if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY || role === contactRoleTypes.DELEGATEFORPRIMARY) {

                                for (var j = 0; j < member.designationViewModels.length; j++) {
                                    if (helperSvc.arrContains(rfrCommissionIds, member.designationViewModels[j].designationId) && !helperSvc.arrContains(primaryAddresses, member.personId)) {
                                        primaryAddresses.push(member.personId);
                                    }
                                }
                            }
                        }

                        organizationSvc.getOrgByIdOdata(rfr.organizationId).then(function (data) {
                            var orgName = data.currentOrganizationDetailDto.organizationName;
                            messageParams[0].messageParameterValue = orgName; //org name
                            messageSvc.buildRecipientListAndSendMessages(primaryAddresses, [], messageTemplateTypes.RFRACCEPTED, null, null, messageParams, contactTypes.INSTITUTIONCONTACTONLY);
                        });                    
                    });

                    factory.data.rfr = data;
                    return factory.data.rfr;
                });
            });
        };

        factory.addEditProgram = function (program, commission, isChangeNameRequest, mode) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/rfr/templates/rfrProgramEdit.html',
                size: 'lg',
                controller: 'rfrProgramEditCtrl',
                resolve: {
                    program: function () { return program; },
                    commission: function () { return commission; },
                    isChangeNameRequest: function () { return isChangeNameRequest; },
                    mode: function () { return mode; }
                }
            });
        };

        factory.getCommissionDtos = function (rfrArg) {
            var commissionDtos = [];
            var rfrObj = rfrArg || factory.data.rfr;

            if (rfrObj && rfrObj.rfrCommissionDtos) {
                commissionDtos = rfrObj.rfrCommissionDtos;
            }

            return commissionDtos;
        };

        factory.getProgramById = function (programId) {
            var commissionDtos = factory.getCommissionDtos();
            var program = null;

            for (var i = 0; i < commissionDtos.length; i++) {
                var programs = commissionDtos[i].jsonContents.programs;

                for (var j = 0; j < programs.length; j++) {
                    var currentProgram = programs[j];

                    if (currentProgram.programId === programId) {
                        program = currentProgram;
                    }
                }
            }

            return program;
        };

        factory.getProgramByCommissionId = function (commissionId) {
            var commissionDtos = factory.getCommissionDtos();
            var programs = [];

            for (var i = 0; i < commissionDtos.length; i++) {
                if (commissionDtos[i].commissionId === commissionId) {
                    programs = commissionDtos[i].jsonContents.programs;
                }
            }

            return programs;
        };

        factory.getProgramArea = function (programName, commissionId) {
            var commissionIdsCSV = commissionId ? (Array.isArray(commissionId) ? commissionId.join(',') : commissionId)
                                                : '';
            programName = programName ? programName : '';
            programName = programSvc.escapeProgramName(programName);
            var searchApiFilter = searchApi + "(programName='" + programName + "',commissionIdsCSV='" + commissionIdsCSV + "')";
            var oSvc = odataSvc.get();

            oSvc.getSource(searchApiFilter, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getAllProgramAreasForCommission = function (commissionId) {
            var oSvc = odataSvc.get();
            var svc = oSvc.getSource(programAreaSocietyApi, '').odata();

            if (Array.isArray(commissionId)) {
                var combinedCommissionPredicate = commissionId.map(function (commissionId) {
                    return new $odata.Predicate('commissionId', '=', parseInt(commissionId))
                });
                if (combinedCommissionPredicate.length > 0) {
                    var predicate = $odata.Predicate.or(combinedCommissionPredicate);
                    svc.filter(predicate);
                }               
            } else {
                var id = commissionId ? parseInt(commissionId) : 0;
                svc.filter('commissionId', id);
            }

            svc.query(oSvc.onSuccess, oSvc.onFailure);
            return oSvc.getDeferred().promise;
        }


        // Assumes the user is passing in data.rfr, NOT just data
        factory.rfrHasCommissionDtos = function (optionalRfr) {
            var rfr = optionalRfr || factory.data.rfr;

            return rfr && rfr.rfrCommissionDtos;
        };

        factory.openRfrPreviewModal = function (rfr) {
            if (rfr) {
                //ensures rfr is being loaded so the summary functions work properly
                factory.data.rfr = deSerializeJsonContents(rfr);
                var modalInstance = $uibModal.open({
                    animation: true,
                    template: '<modal hide-validation-key="true" print-button="true"><modal-body><rfr-summary is-submit-mode="false" is-preview-mode="true" rfr="rfr"></rfr-summary></modal-body></modal>',
                    windowClass: 'page-color',
                    size: 'xl',
                    controller: 'rfrPreviewRevisionCtrl',
                    resolve: {
                        rfr: function () { return rfr; }
                    }
                });
            } else {
                console.error('No rfr was passed into rfrSvc.openRfrPreviewModal()');
            }
        };

        factory.getProgramCampusTypeName = function (selectedProgramCampusTypeId) {
            var programCampusTypes = typeConstSvc.getProgramCampusTypes();
            var programCampusType = programCampusTypes.find(function (type) {
                return type.programCampusTypeId === selectedProgramCampusTypeId;
            });
            var progamCampusTypeName = programCampusType ? programCampusType.programCampusTypeName : '';

            return progamCampusTypeName;
        };

        factory.convertToCampusesDto = function (program) {
            var campusesDto = {
                programCampusTypeId: 0,
                campuses: []
            };

            campusesDto.programCampusTypeId = program.programCampusTypeId;
            campusesDto.programCampusTypeName = factory.getProgramCampusTypeName(program.programCampusTypeId);



            angular.forEach(program.campuses,
                function (campus) {
                    var campusDto = createCampusDto(campus);
                    campusDto.campusName = campus.campusName;
                    campusDto.distanceMiles = campus.distanceMiles ? Math.round(Number(campus.distanceMiles)) : campus.distanceMiles;
                    campusDto.isMainCampus = campus.isMainCampus;
                    campusDto.note = campus.note;

                    campusesDto.campuses.push(campusDto);
                });

            return campusesDto;
        };

        factory.convertOrgToCampusDto = function (organization) {
            var address = organization.currentOrganizationAddressDto;
            var campusDto = createCampusDto(organization.currentOrganizationAddressDto);
            campusDto.campusName = 'Main Campus';
            campusDto.distanceMiles = 0;
            campusDto.isMainCampus = true;
            campusDto.note = null;

            return campusDto;
        };

        factory.convertToProgramCampuses = function (campusesDto) {
            var campuses = [];
            angular.forEach(campusesDto.campuses,
                function (campus) {
                    if (!campus.isDeleted) {
                        campuses.push({
                            isMainCampus: campus.isMainCampus,
                            distanceMiles: campus.distanceMiles,
                            campusName: campus.campusName,
                            address1: campus.addressDto.address1,
                            address2: campus.addressDto.address2,
                            address3: campus.addressDto.address3,
                            city: campus.addressDto.city,
                            province: campus.addressDto.province,
                            stateCode: campus.addressDto.stateCode,
                            stateName: campus.addressDto.stateName,
                            postalCode: campus.addressDto.postalCode,
                            countryCode: campus.addressDto.countryCode,
                            countryName: campus.addressDto.countryName,
                            note: campus.note
                        });
                    };
                });

            return campuses;
        }

        //get all programs from all commissions
        factory.getAllPrograms = function (rfr) {

            var commissionDtos;
            var programs = [];

            if(rfr)
                commissionDtos = factory.getCommissionDtos(rfr);            
            else
                commissionDtos = factory.getCommissionDtos();

            if (commissionDtos) {
                for (var i = 0; i < commissionDtos.length; i++) {
                    programs.push.apply(programs, commissionDtos[i].jsonContents.programs);
                }
            }
            return programs;
        }

        //sets the new program in the current rfr with a contact
        factory.addNewProgramContact = function (personId, program, updatedRfr) {
            var commissionDtos = factory.getCommissionDtos();

            if (commissionDtos.length > 0) {
                for (var i = 0; i < commissionDtos.length; i++) {
                    var programs = commissionDtos[i].jsonContents.programs;

                    for (var j = 0; j < programs.length; j++) {
                        var currentProgram = programs[j];

                        if (angular.toJson(currentProgram) === angular.toJson(program)) {
                            if (!currentProgram.programContactPersons) {
                                currentProgram.programContactPersons = [];
                            }
                            // Check if person has already been added.
                            var preExistingContact = currentProgram.programContactPersons.find(function (person) { return (person.personId === personId); });
                            if (preExistingContact === undefined) {
                                currentProgram.programContactPersons.push({ personId: personId });

                                //set the current RFR commission program to this updated one
                                if (updatedRfr === null || updatedRfr === undefined) {
                                    updatedRfr = angular.copy(factory.data.rfr)
                                };
                                updatedRfr.rfrCommissionDtos[i].jsonContents.programs[j] = currentProgram;
                            }

                            return updatedRfr;
                        }
                    }
                }
            }

        };

        //gets all the new program contacts with the program name attached, optional bool to return indexes to find where contacts are
        factory.getNewProgramContacts = function (optionalIndexes) {
            var commissionDtos = factory.getCommissionDtos();
            var newContacts = [];

            if (commissionDtos.length > 0) {
                for (var i = 0; i < commissionDtos.length; i++) {
                    var programs = commissionDtos[i].jsonContents.programs;

                    if (programs) {
                        for (var j = 0; j < programs.length; j++) {
                            var currentProgram = programs[j];

                            if (currentProgram.programContactPersons && currentProgram.programContactPersons.length > 0)
                                for (var k = 0; k < currentProgram.programContactPersons.length; k++) {
                                    if (optionalIndexes)
                                        newContacts.push({ programName: currentProgram.programName, personId: currentProgram.programContactPersons[k].personId, commissionIndex: i, programIndex: j, personIndex: k });
                                    else
                                        newContacts.push({ programName: currentProgram.programName, personId: currentProgram.programContactPersons[k].personId });
                                }
                        }
                    }
                }
            }
            return newContacts;
        }

        factory.deleteNewProgramContact = function (contact, optionalIdToReplace) {
            var newProgramContacts = factory.getNewProgramContacts(true);

            for (var i = 0; i < newProgramContacts.length; i++) {
                if (newProgramContacts[i].personId === contact.personId) {
                    if (optionalIdToReplace) 
                        factory.data.rfr.rfrCommissionDtos[newProgramContacts[i].commissionIndex].jsonContents.programs[newProgramContacts[i].programIndex].programContactPersons.splice(newProgramContacts[i].personIndex, 1, optionalIdToReplace);
                    else
                        factory.data.rfr.rfrCommissionDtos[newProgramContacts[i].commissionIndex].jsonContents.programs[newProgramContacts[i].programIndex].programContactPersons.splice(newProgramContacts[i].personIndex, 1);
                }
            }

            var updatedRfr = angular.copy(factory.data.rfr)
            factory.update(updatedRfr);
        }

        //messages sent to Abet Admin and primary contacts
        factory.submitForAbetPreReview = function (commission) {
            var commissionIndex = factory.data.rfr.rfrCommissionDtos.map(function (e) { return e.commissionId; }).indexOf(commission.commissionId);
            var now = new Date();
            factory.data.rfr.rfrCommissionDtos[commissionIndex].termsAcceptedTimestamp = now;
            factory.data.rfr.rfrCommissionDtos[commissionIndex].termsAcceptedByUserId = currentUser.profile.userId;
            factory.data.rfr.rfrCommissionDtos[commissionIndex].submittedTimestamp = now;
            factory.data.rfr.rfrCommissionDtos[commissionIndex].submittedByUserId = currentUser.profile.userId;

            if (factory.allCommissionsSubmitted()) {

                factory.data.rfr.rfrProcessStatusId = rfrProcessStatuses.WAITINGFORABETPREREVIEW;               

                membershipSvc.getByOrganizationId(factory.data.rfr.organizationId, true).then(function (data) {
                    //convert the message contents
                    var messageParams = angular.copy(messageTemplateParameters.RFRPREREVIEWREQUEST);
                    messageParams[0].messageParameterValue = currentOrganization.profile.organizationName;//org
                    angular.forEach(factory.data.rfr.rfrCommissionDtos, function (commission) {
                        messageParams[1].messageParameterValue === '' ? messageParams[1].messageParameterValue = commission.commissionAbbreviatedName : messageParams[1].messageParameterValue += (', ' + commission.commissionAbbreviatedName); //commissions
                    });
                    messageParams[2].messageParameterValue = helperSvc.getAcademicYear().toString();


                    var members = helperSvc.getResults(data);
                    var personIds = [];
                    var rfrCommissionIds = getCommissionIds(factory.data.rfr);

                    for (var i = 0; i < members.length; i++) {
                        var member = members[i];
                        var role = member.roleId;

                        if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY || role === contactRoleTypes.DELEGATEFORPRIMARY) {

                            for (var j = 0; j < member.designationViewModels.length; j++) {
                                if (helperSvc.arrContains(rfrCommissionIds, member.designationViewModels[j].designationId) && !helperSvc.arrContains(personIds, member.personId)) {
                                    personIds.push(member.personId);
                                }
                            }
                        }
                    }

                    membershipSvc.getAbetAdmins().then(function (data) {
                        var admins = data;
                        var adminEmails = [];

                        angular.forEach(admins, function (value) {
                            adminEmails.push(value.personDto.personEmailDto.emailAddress);
                        });
                  
                        messageSvc.buildRecipientListAndSendMessages(adminEmails, personIds, messageTemplateTypes.RFRPREREVIEWREQUEST, null, null, messageParams, contactTypes.INSTITUTIONCONTACTONLY)
                    });

                   
                });
            }

            return factory.update(factory.data.rfr);
        }


        factory.rejectCommissionRfr = function (commissionsRejected, rfr, isPresidentReturn) {
            
            //manipulate rfr contents
            if (commissionsRejected && commissionsRejected.length > 0) {
                var commissionId;

                for (var i = 0; i < commissionsRejected.length; i++) {
                    commissionId = commissionsRejected[i].commissionId;

                   var commissionDtos = factory.getCommissionDtos(rfr);

                    for (var j = 0; j < commissionDtos.length; j++) {
                        if (commissionDtos[j].commissionId === commissionId) {
                            commissionDtos[j].submittedTimestamp = null;
                            commissionDtos[j].submittedByUserId = null;
                            break;
                        }
                    }
                }

                rfr.rfrProcessStatusId = rfrProcessStatuses.WAITINGFORSUBMISSIONTOABET;

                if (isPresidentReturn) {
                    //start the emails sending process
                    membershipSvc.getByOrganizationId(rfr.organizationId, true).then(function (data) {
                        var messageParams = angular.copy(messageTemplateParameters.RFRRETURNEDTOCOMMISSION);
                        messageParams[0].messageParameterValue = currentOrganization.profile.organizationName; //org name
                        messageParams[2].messageParameterValue = rfr.reviewYear.toString(); //review cycle year
                        var members = helperSvc.getResults(data);
                        var selectedContacts = [];
                        var primaryAddresses = [];
                        var ccdAddresses = [];
                        var alreadySelectedPresidents = [];
                        var member;
                        var role;

                        //Get all the primary addresses for those people whose commissions were rejected
                        for (var j = 0; j < commissionsRejected.length; j++) {

                            for (var i = 0; i < members.length; i++) {
                                member = members[i];
                                role = member.roleId;

                                if (role === contactRoleTypes.PRESIDENT || role === contactRoleTypes.PROVOST || role === contactRoleTypes.DELEGATEFORPRESIDENT) {
                                    if (alreadySelectedPresidents.length === 0) {
                                        alreadySelectedPresidents.push(member.personId);
                                        messageParams[1].messageParameterValue = member.namePrefixCode + " " + member.lastName;//president last name(s)
                                    }
                                    else {
                                        for (var p = 0; p < alreadySelectedPresidents.length; p++) {
                                            if (!helperSvc.arrContains(alreadySelectedPresidents, member.personId)) {
                                                messageParams[1].messageParameterValue += (' and ' + member.namePrefixCode + " " + member.lastName); //president last name(s)
                                            }
                                        }
                                    }
                                }

                                if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY || role === contactRoleTypes.DELEGATEFORPRIMARY) {
                                    selectedContacts.push(member.personId);

                                    for (var k = 0; k < member.designationViewModels.length; k++) {

                                        if (!helperSvc.arrContains(primaryAddresses, member.personId) && member.designationViewModels[k].designationId === commissionsRejected[j].commissionId) {
                                            primaryAddresses.push(member.personId);
                                        }
                                    }
                                }
                            }
                        }

                        //get all the contacts addresses for those need to be ccd but commissions are acceptable
                        for (var i = 0; i < selectedContacts.length; i++) {
                            if (!helperSvc.arrContains(primaryAddresses, selectedContacts[i])) {
                                ccdAddresses.push(selectedContacts[i]);
                            }
                        }

                        messageSvc.buildRecipientListAndSendMessages(primaryAddresses, ccdAddresses, messageTemplateTypes.RFRRETURNEDTOCOMMISSION, null, null, messageParams, contactTypes.INSTITUTIONCONTACTONLY);
                    });
                }
            }
            return factory.update(rfr);

        }

        
        //ABET ADMIN ACCEPT RFR PRE REVIEW **WITH EMAIL SVC**
        factory.acceptRfrPreReview = function(rfr){

            rfr.rfrProcessStatusId = rfrProcessStatuses.WAITINGFORPRESIDENTSIGNATURE;
            // Accepted-by user id and timestamp set in WebAPI

            //send emails to president and CC contacts
            membershipSvc.getByOrganizationId(rfr.organizationId, true).then(function (data) {
                var messageParams = angular.copy(messageTemplateParameters.RFRREQUESTPRESIDENTSIGNATURE);               
                messageParams[2].messageParameterValue = (helperSvc.getAcademicYear()).toString() + '-' + rfr.reviewYear.toString().substr(2, 2); //review cycle year span

                var members = helperSvc.getResults(data);
                var primaryAddresses = [];
                var ccdAddresses = [];
                var rfrCommissionIds = getCommissionIds(factory.data.rfr);

                for (var i = 0; i < members.length; i++) {
                    var member = members[i];
                    var role = member.roleId;

                    if (role === contactRoleTypes.PRESIDENT || role === contactRoleTypes.PROVOST || role === contactRoleTypes.DELEGATEFORPRESIDENT) {
                        primaryAddresses.push(member.personId);
                        messageParams[1].messageParameterValue === '' ? messageParams[1].messageParameterValue = member.namePrefixCode + " " + member.lastName : messageParams[1].messageParameterValue += (' and ' + member.namePrefixCode + " " + member.lastName); //president last name(s)
                    }
                    if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY || role === contactRoleTypes.DELEGATEFORPRIMARY) {

                        for (var j = 0; j < member.designationViewModels.length; j++) {
                            if (helperSvc.arrContains(rfrCommissionIds, member.designationViewModels[j].designationId) && !helperSvc.arrContains(ccdAddresses, member.personId)) {
                                ccdAddresses.push(member.personId);
                            }
                        }
                    }
                }

                
                organizationSvc.getOrgByIdOdata(rfr.organizationId).then(function (data) {
                    var orgName = data.currentOrganizationDetailDto.organizationName;
                    messageParams[0].messageParameterValue = orgName; //org name
                    messageSvc.buildRecipientListAndSendMessages(primaryAddresses, ccdAddresses, messageTemplateTypes.RFRREQUESTPRESIDENTSIGNATURE, null, null, messageParams, contactTypes.INSTITUTIONCONTACTONLY)
                });

            });

            return factory.update(rfr);
        }


        //FINAL SUBMIT TO ABET FOR ACCEPTANCE **WITH EMAIL SVC**
        factory.submitToAbet = function (commission) {
            var commissionIndex = factory.data.rfr.rfrCommissionDtos.map(function (e) { return e.commissionId; }).indexOf(commission.commissionId);
            var now = new Date();
            factory.data.rfr.rfrCommissionDtos[commissionIndex].submittedTimestamp = now;
            factory.data.rfr.rfrCommissionDtos[commissionIndex].submittedByUserId = currentUser.profile.userId;

            if (factory.allCommissionsSubmitted()) {
                factory.data.rfr.rfrProcessStatusId = rfrProcessStatuses.WAITINGFORABETACCEPTANCE;
                factory.data.rfr.submittedTimestamp = now;
                factory.data.rfr.submittedByUserId = currentUser.profile.userId;

                //cc primary and secondary contacts, and send to Abet admin
                membershipSvc.getByOrganizationId(factory.data.rfr.organizationId, true).then(function (data) {
                    var messageParams = angular.copy(messageTemplateParameters.RFRREQUESTABETACCEPTANCE);
                    messageParams[0].messageParameterValue = currentOrganization.profile.organizationName; //org name
                    var members = helperSvc.getResults(data);
                    var personIds = [];
                    var rfrCommissionIds = getCommissionIds(factory.data.rfr);

                    for (var i = 0; i < members.length; i++) {
                        var member = members[i];
                        var role = member.roleId;

                        if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY || role === contactRoleTypes.DELEGATEFORPRIMARY) {

                            for (var j = 0; j < member.designationViewModels.length; j++) {
                                if (helperSvc.arrContains(rfrCommissionIds, member.designationViewModels[j].designationId) && !helperSvc.arrContains(personIds, member.personId)) {
                                    personIds.push(member.personId);
                                }
                            }
                        }
                    }

                    membershipSvc.getAbetAdmins().then(function (data) {
                        var admins = data;
                        var adminEmails = [];

                        angular.forEach(admins, function (value) {
                            adminEmails.push(value.personDto.personEmailDto.emailAddress);
                        });

                        messageSvc.buildRecipientListAndSendMessages(adminEmails, personIds, messageTemplateTypes.RFRREQUESTABETACCEPTANCE, null, null, messageParams, contactTypes.INSTITUTIONCONTACTONLY)
                    });
                });

            }            

            return factory.update(factory.data.rfr);
        }


        factory.presidentSubmitToAbet = function (rfr) {

            //cc primary and secondary contacts, and send to Abet admin
            membershipSvc.getByOrganizationId(rfr.organizationId, true).then(function (data) {
                var messageParams = angular.copy(messageTemplateParameters.RFRREQUESTABETACCEPTANCE);
                messageParams[0].messageParameterValue = currentOrganization.profile.organizationName; //org name
                var members = helperSvc.getResults(data);
                var personIds = [];
                var rfrCommissionIds = getCommissionIds(rfr);

                for (var i = 0; i < members.length; i++) {
                    var member = members[i];
                    var role = member.roleId;

                    if (role === contactRoleTypes.PRIMARY || role === contactRoleTypes.SECONDARY || role === contactRoleTypes.DELEGATEFORPRIMARY) {

                        for (var j = 0; j < member.designationViewModels.length; j++) {
                            if (helperSvc.arrContains(rfrCommissionIds, member.designationViewModels[j].designationId) && !helperSvc.arrContains(personIds, member.personId)) {
                                personIds.push(member.personId);
                            }
                        }
                    }
                }

                membershipSvc.getAbetAdmins().then(function (data) {
                    var admins = data;
                    var adminEmails = [];

                    angular.forEach(admins, function (value) {
                        adminEmails.push(value.personDto.personEmailDto.emailAddress);
                    });

                    messageSvc.buildRecipientListAndSendMessages(adminEmails, personIds, messageTemplateTypes.RFRREQUESTABETACCEPTANCE, null, null, messageParams, contactTypes.INSTITUTIONCONTACTONLY)
                });
            });               

            return factory.update(rfr);
        }

        //Takes in the rfr, calculates the pev number for each discipline and then assigns that number to the resective program discipline
        factory.calculateAllPevsForRfr = function (rfr) {           
            var commissions = factory.getCommissionDtos(rfr);
            var simultaneousAccountedFor1 = false;
            var simultaneousAccountedFor2 = false;

            for (var i = 0; i < commissions.length; i++) {
                //NO PEVS if a focused reprot (IR)
                if (commissions[i].reviewTypeCode !== reviewTypeIds.INTERIMREPORT) {
                    var reviewType = commissions[i].reviewTypeCode;
                    var programs = commissions[i].jsonContents.programs;
                    var simultaneousOption = commissions[i].simultaneousOptionSeq;

                    //check that simulataneous is not checked off already and that this program is part of it
                    var nonTerminatedPrograms = programs.filter(function (program) {
                        return program.programReviewTypeCode !== null &&
                               program.programReviewTypeCode !== programReviewTypeIds.TERMINATIONREPORT &&
                            program.programReviewTypeCode !== programReviewTypeIds.TERMINATIONVISIT &&
                            program.programReviewTypeCode !== programReviewTypeIds.TERMINATION ;
                    });
                    if (nonTerminatedPrograms.length === 1) {
                        if (nonTerminatedPrograms[0].disciplines.length === 1) {
                            var isJoint = isJointProgram(nonTerminatedPrograms[0], commissions[i].commissionId, rfr);

                            nonTerminatedPrograms[0].disciplines[0].pevsRequired = rfrLogicSvc.getSingleProgramPevNumber(nonTerminatedPrograms[0], true, simultaneousOption, isJoint);
                        } else {
                            angular.forEach(nonTerminatedPrograms[0].disciplines, function (disc) {
                                disc.pevsRequired = rfrLogicSvc.getSingleProgramPevNumber(nonTerminatedPrograms[0]);
                            });
                        }
                    }
                    else {
                        angular.forEach(programs, function (prog) {
                            angular.forEach(prog.disciplines, function (disc) {
                                disc.pevsRequired = rfrLogicSvc.getSingleProgramPevNumber(prog);
                            });
                        });
                    }
                } else {
                    angular.forEach(commissions[i].jsonContents.programs, function (prog) {
                        angular.forEach(prog.disciplines, function (disc) {
                            disc.pevsRequired = 0;
                        })
                    })
                }
            }
            return rfr;
        }


        factory.allCommissionsSubmitted = function () {
            var commissionDtos = factory.getCommissionDtos();
            if (commissionDtos && commissionDtos.length) {
                return commissionDtos.every(function (commissionDto) {
                    return commissionDto.submittedTimestamp;
                });
            } else {
                return true;
            }
        };

        factory.isCommissionFocusedReport = function(rfrCommission){
            return rfrCommission.reviewTypeCode === programReviewTypeIds.FOCUSEDREPORT;
        }

        factory.checkForAllCommissionsAreFocusedReport = function (rfr) {
            var commissions = factory.getCommissionDtos(rfr);
            var result = true;
            angular.forEach(commissions, function (commission) {
                if(!factory.isCommissionFocusedReport(commission)){
                    result = false;
                }
            });

            return result;
        }

        factory.getIRCommissionIds = function (rfr) {
            var commissions = factory.getCommissionDtos(rfr);
            var result = [];
            angular.forEach(commissions, function (commission) {
                if (factory.isCommissionFocusedReport(commission)) {
                    result.push(commission.commissionId);
                }
            });

            return result;
        }

        //gather all campus address from rfr
        factory.getCampusLocations = function (allowDuplicates) {
            var otherCampusCount = 0;
            var mainCampusCount = 0;
            var rfrMainCampuses = [];
            var rfrOtherCampuses = [];
            var commissionDtos = factory.getCommissionDtos();
            var campusData = {};

            angular.forEach(commissionDtos, function (commissionDto) {
                angular.forEach(commissionDto.jsonContents.programs, function (program) {
                    if (program.campuses && program.campuses.length > 0) {
                        var campusDto = factory.convertToCampusesDto(program);

                        angular.forEach(campusDto.campuses, function (address) {
                            if (!address.isMainCampus) {
                                if (!helperSvc.arrContains(rfrOtherCampuses, address) || allowDuplicates) {
                                    rfrOtherCampuses.push(address);
                                }

                                otherCampusCount += 1;
                            }
                            else if (address.isMainCampus) {
                                if (!helperSvc.arrContains(rfrMainCampuses, address)) {
                                    
                                    rfrMainCampuses.push(address);
                                }
                                
                                mainCampusCount += 1;
                            }
                        });
                    }
                });
            });

            var returnCampuses = [];

            if (rfrMainCampuses.length > 0) {
                returnCampuses = rfrMainCampuses.concat(rfrOtherCampuses);
            }

            return { 'campusLocations': returnCampuses, 'otherCampusCount': otherCampusCount, 'mainCampusCount': mainCampusCount };
        };

        factory.isAuthorizedToEditRFE = function (commission) {
            var organizationId = factory.data.rfr.organizationId;
            var commissionId = commission.commissionId;

            // Check Authorization
            var isAdmin = oauth.isAdmin();
            var isDesignated = oauth.isDesignated(organizationId, commissionId);
            var isPresident = currentUserHelper.isPresident(organizationId);
            var isDelegateForPresident = currentUserHelper.isDelegateForPresident(organizationId);
            var isDelegateForPrimary = currentUserHelper.isDelegateForPrimary(organizationId, commissionId);

            return isAdmin || isDesignated || isPresident || isDelegateForPresident || isDelegateForPrimary;
        };

        // PDF Modal Funcs
        var pdfPath = 'Content/files/RFE-Instructions-11272024.pdf';
        var pageNumbers = {
            REVIEWOPTIONS: 6,
            PROGRAMS: 10,
            CONTACTS: 21,
            SUBMIT: 26,
            SIGN: 29
        };

        factory.openMasterInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'RFE Instructions');
        };

        factory.openReviewOptionsInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Review Options Instructions', pageNumbers.REVIEWOPTIONS);
        };

        factory.openProgramInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Program Info Instructions', pageNumbers.PROGRAMS);
        };

        factory.openContactInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Manage Contacts Instructions', pageNumbers.CONTACTS);
        };

        factory.openSubmissionInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Preview & Submit Instructions', pageNumbers.SUBMIT);
        };

        factory.openSignInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Sign by President Instructions', pageNumbers.SIGN);
        };

        factory.updateWithNPC = function (rfr) {

            var path = amsConst.webApiUrl + '/odata/UpdateWithNPC';

            factory.calculateAllPevsForRfr(rfr);
            cleanValidationErrorArrays(rfr);
            setDefaultCriteriaCycleYear(rfr)
            var updatedRfr = serializeJsonContents(rfr);

            var data = { "rfr": updatedRfr };

            return $http.post(path, data);
        }

        function createCampusDto(obj) {
            var campusDto = {
                addressDto: {
                    address1: obj.address1,
                    address2: obj.address2,
                    address3: obj.address3,
                    addressId: 0,
                    city: obj.city,
                    province: obj.province,
                    countryCode: obj.countryCode,
                    countryName: obj.countryName,
                    isDeleted: false,
                    postalCode: obj.postalCode,
                    stateCode: obj.stateCode,
                    stateName: obj.stateName
                },
                addressId: 0,
                changeId: null,
                isDeleted: false,
                isOutsideUS: (obj.countryCode !== 'US'),
                programReviewCampusId: 0,
                programReviewId: null
            };

            return campusDto;
        }

        function setDefaultCriteriaCycleYear(rfr) {
            if (!rfr || !Array.isArray(rfr.rfrCommissionDtos)) return;
            var reviewYear = rfr.reviewYear;
            rfr.rfrCommissionDtos.forEach(function (commission) {
                if (!commission.jsonContents || !Array.isArray(commission.jsonContents.programs)) return;
                commission.jsonContents.programs.forEach(function (program) {
                    if (!factory.isInterim(program) && !program.criteriaCycleYear) {
                        program.criteriaCycleYear = reviewYear;
                    }
                });
            });
        }

        function serializeJsonContents(rfr) {

            if (rfr.rfrCommissionDtos != null && rfr.rfrCommissionDtos.length > 0) {
                rfr.rfrCommissionDtos.forEach(function (commission) {
                    commission.jsonContents = angular.toJson(commission.jsonContents);                  
                });
            }

            return rfr;
        }

        function deSerializeJsonContents(rfr) {
            if (rfr.rfrCommissionDtos != null && rfr.rfrCommissionDtos.length > 0) {
                rfr.rfrCommissionDtos.forEach(function (commission) {
                    commission.jsonContents = angular.fromJson(commission.jsonContents);
                    if (commission.jsonContents.programs && commission.jsonContents.programs.length > 0)
                        commission.jsonContents.programs.sort(function (a, b) { return (a.programName > b.programName) ? 1 : ((b.programName > a.programName) ? -1 : 0); });
                    else
                        commission.jsonContents.programs = [];

                });
            }
            return rfr;
        }

        function getCommissionIds(rfr) {
            var commissionIds = [];

            if (rfr.rfrCommissionDtos != null && rfr.rfrCommissionDtos.length > 0) {
                angular.forEach(rfr.rfrCommissionDtos, function (value) {
                    commissionIds.push(value.commissionId);
                })
            }

            return commissionIds;
        }

        function isJointProgram(program, programCommission, rfr) {
            var commissions = factory.getCommissionDtos(rfr);
            var programName = program.programName;
            var programDegree = program.degreeCode;

            for (var i = 0; i < commissions.length; i++) {
                if (programCommission !== commissions[i].commissionId) {

                    for (var j = 0; j < commissions[i].jsonContents.programs.length; j++) {
                        var program = commissions[i].jsonContents.programs[j];
                        if (program.programName === programName) {
                            if (program.degreeCode === programDegree) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        }

        //store local copy of rfr data
        function resolveRfrData(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.data.rfr = data;

                angular.forEach(factory.data.rfr.rfrCommissionDtos, function (value, key) {
                    factory.data.rfr.rfrCommissionDtos[key].jsonContents = angular.fromJson(value.jsonContents);
                    //new rfe checks to make sure the rfe's commission has a program array in the json conents
                    if (!Array.isArray(factory.data.rfr.rfrCommissionDtos[key].jsonContents.programs)) {
                        factory.data.rfr.rfrCommissionDtos[key].jsonContents.programs = [];
                    } else {
                        angular.forEach(factory.data.rfr.rfrCommissionDtos[key].jsonContents.programs, function (program) {
                            if (Array.isArray(program.campuses)) {
                                angular.forEach(program.campuses, function (campus) {
                                    if (campus.distanceMiles == null) return;
                                    // Convert to integer for storage as integer with program review 
                                    campus.distanceMiles = Math.round(Number(campus.distanceMiles));
                                });
                            }
                        });
                    }
                    if (factory.data.rfr.rfrCommissionDtos[key].jsonContents.programs && factory.data.rfr.rfrCommissionDtos[key].jsonContents.programs.length > 1)
                        factory.data.rfr.rfrCommissionDtos[key].jsonContents.programs.sort(function (a, b) { return (a.programName > b.programName) ? 1 : ((b.programName > a.programName) ? -1 : 0); });
                });
            });
        }

        function cleanValidationErrorArrays(rfr) {

            //deletes rfr error array
            if (rfr.errors)
                delete rfr.errors;

            //deletes commission error array
            var commissions = factory.getCommissionDtos(rfr);
            angular.forEach(commissions, function (commission) {
                if (commission.errors)
                    delete commission.errors;
            });

            //deletes program error array
            var programs = factory.getAllPrograms(rfr);
            angular.forEach(programs, function (program) {
                if (program.errors)
                    delete program.errors;
            });
        }

        factory.isWithinRevisionCutoffDate = function (rfr) {
            var cutOffDate = new Date(Date.UTC((rfr.reviewYear - 1), 1, 15));
            var date = new Date();
            var currentDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

            if(currentDate > cutOffDate)
                return false
            else
                return true

        }

        factory.isInterim = function (program) {
            if (!program) return false;

            switch (program.programReviewTypeCode) {
                case programReviewTypeIds.FOCUSEDREPORT:
                case programReviewTypeIds.FOCUSEDVISIT:
                case programReviewTypeIds.FOCUSEDSHOWCAUSE:
                case programReviewTypeIds.SHOWCAUSEREPORT:
                case programReviewTypeIds.SHOWCAUSEVISIT:
                    return true;
            }

            return false;
        }

        factory.getRfrCommissionById = function (rfrCommissionId) {
            var oSvc = odataSvc.get();
            var apiPath = amsConst.webApiUrl + '/odata/' + '/GetRfrCommissionById' ;
            var rfrPath = apiPath + "(rfrCommissionId=" + rfrCommissionId + ")";

            return $http.get(rfrPath).then(function (data) { return data.data }, function () { });
        }

        factory.updateRfrCommission = function (rfrCom) {
            var oSvc = odataSvc.get();
            var path = amsConst.webApiUrl + '/odata/UpdateRfrCommission';

            rfrCom.jsonContents = JSON.stringify(rfrCom.jsonContents);

            var updatedCom = serializeJsonContents(rfrCom);
            var data = { rfrCommission: updatedCom };

            return $http.post(path, data);
        }

        factory.getCAOLabel = function (rfr, cao) {
            // Dr. Kenimer became CAO mid-2024, so use her name and title for all RFEs from 2026 onward unless otherwise specified
            const caoLabel = { name: 'Ann L. Kenimer, Ph.D.', title: 'Chief Accreditation Officer' };

            if (rfr?.reviewYear <= 2025) {
                // RFEs for 2025 and earlier were under Dr. Sussman as CAO
                caoLabel.name = 'Joseph L. Sussman, P.E., PhD.';
                caoLabel.title = 'Chief Accreditation Office & Chief Information Officer';
            } else if (cao) {
                // Use name of whomever was CAO during at time of RFE submission if specified
                const { firstName, middleName, lastName, nameSuffix, professionalSuffixCode } = cao.personDto;
                const middle = middleName?.length === 1 ? `${middleName}.` : middleName ?? '';
                caoLabel.name = `${firstName} ${middle ? middle + ' ' : ''}${lastName}${nameSuffix ? `, ${nameSuffix}` : ''}${professionalSuffixCode ? `, ${professionalSuffixCode}` : ''}`;
            } 

            return caoLabel;
        }

        //factory.checkRestrictionConflicts = function (commissionDtos) {
        //    var valueArray = [];
        //    var hasConflicts = false;

        //    commissionDtos.forEach(com => {
        //        if (com.jsonContents.onsiteVisitExpectation && com.jsonContents.onsiteVisitExpectation.selection) {
        //            valueArray.push(com.jsonContents.onsiteVisitExpectation.selection);
        //        }
        //    })

        //    if (valueArray.length > 1)
        //        hasConflicts = helperSvc.anyDifferentValueInArray(valueArray);

        //    return hasConflicts;
        //}

        return {
            getRfrSubmissionAndCommissions: factory.getRfrSubmissionAndCommissions,
            getRfrSubmissionsByOrganizationId: factory.getRfrSubmissionsByOrganizationId,
            create: factory.create,
            createRevisedRfr: factory.createRevisedRfr,
            update: factory.update,
            delete: factory.delete,
            data: factory.data,
            slugs: factory.slugs,
            addEditProgram: factory.addEditProgram,
            getCommissionDtos: factory.getCommissionDtos,
            getProgramById: factory.getProgramById,
            getProgramByCommissionId: factory.getProgramByCommissionId,
            getProgramArea: factory.getProgramArea,
            getCampusLocations: factory.getCampusLocations,
            rfrHasCommissionDtos: factory.rfrHasCommissionDtos,
            validate: factory.validate,
            openRfrPreviewModal: factory.openRfrPreviewModal,
            getProgramCampusTypeName: factory.getProgramCampusTypeName,
            convertToCampusesDto: factory.convertToCampusesDto,
            convertOrgToCampusDto: factory.convertOrgToCampusDto,
            convertToProgramCampuses: factory.convertToProgramCampuses,
            getAllPrograms: factory.getAllPrograms,
            getAllProgramAreasForCommission: factory.getAllProgramAreasForCommission,
            addNewProgramContact: factory.addNewProgramContact,
            getNewProgramContacts: factory.getNewProgramContacts,
            submitToAbet: factory.submitToAbet,
            allCommissionsSubmitted: factory.allCommissionsSubmitted,
            deleteNewProgramContact: factory.deleteNewProgramContact,
            accept: factory.accept,
            submitForAbetPreReview: factory.submitForAbetPreReview,
            rejectCommissionRfr: factory.rejectCommissionRfr,
            acceptRfrPreReview: factory.acceptRfrPreReview,
            presidentSubmitToAbet: factory.presidentSubmitToAbet,
            calculateAllPevsForRfr: factory.calculateAllPevsForRfr,
            isCommissionFocusedReport: factory.isCommissionFocusedReport,
            checkForAllCommissionsAreFocusedReport: factory.checkForAllCommissionsAreFocusedReport,
            openMasterInstructions: factory.openMasterInstructions,
            openReviewOptionsInstructions: factory.openReviewOptionsInstructions,
            openProgramInstructions: factory.openProgramInstructions,
            openContactInstructions: factory.openContactInstructions,
            openSubmissionInstructions: factory.openSubmissionInstructions,
            openSignInstructions: factory.openSignInstructions,
            createNewRfrByAdmin: factory.createNewRfrByAdmin,
            isWithinRevisionCutoffDate: factory.isWithinRevisionCutoffDate,
            isAuthorizedToEditRFE: factory.isAuthorizedToEditRFE,
            getIRCommissionIds: factory.getIRCommissionIds,
            isInterim: factory.isInterim,
            updateWithNPC: factory.updateWithNPC,
            updateRfrCommission: factory.updateRfrCommission,
            getRfrCommissionById: factory.getRfrCommissionById,
            getCAOLabel: factory.getCAOLabel,
/*            checkRestrictionConflicts: factory.checkRestrictionConflicts*/
        };
    };

    module.factory('rfrSvc', rfrSvc);

})(angular.module('rfr'));